const colors = {
    black: '#000000',
    color1: '#222222',
    color2: '#9BDCF0',
    color3: '#CFA22E',
    darkGray: '#929292',
    basketGray: '#909090',
    darkerGray: '#737373',
    green: '#001E64',
    lightGreen: 'rgba(0, 204, 106, 0.5)',
    header: '#F8F8F8',
    lightGray: '#F3F0F0',
    midGray: '#C4C4C4',
    warning: '#EB5757',
    lightWarning: 'rgba(235, 87, 87, 0.20)',
    white: '#FFFFFF',
    basketInfoIcon: 'rgba(34, 34, 34, 0.54)',
    buttonPrimaryColor: '#001E64',
}

export default colors
