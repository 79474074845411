import { Box, Grid, TextField, Typography } from '@material-ui/core'
import ErrorBox from 'components/ErrorBox'
import InfoTooltip from 'components/InfoTooltip'
import OptionItem, { OptionItemContent } from 'components/OptionItem/OptionItem'
import RadioOptionGroup from 'components/RadioOptionGroup'
import ViewWrapper from 'components/ViewWrapper'
import { BillMode } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'
import { GeneralState, InstallationDetailsState, OptionType } from 'store/GeneralState/GeneralState.reducer'
import TestIDs from 'utils/TestIDs'
import {
    ConfigurationHouseConnection,
    ConfigurationInstallationService,
    ConfigurationJunctionBox,
    ConfigurationOpticFiber,
    Customize,
} from 'utils/customize'
import { InstallationDetailsFields, useInstallationDetailsReducer } from './useInstallationDetailsReducer'

export interface RenderInstallationDetailsProps {
    convertJunctionBoxOptionToOptionItemContent: (junctionBox: ConfigurationJunctionBox) => OptionItemContent[]
    convertHouseConnectionOptionToOptionItemContent: (
        houseConnection: ConfigurationHouseConnection,
    ) => OptionItemContent[]
    convertOpticFiberOptionToOptionItemContent: (opticFiber: ConfigurationOpticFiber) => OptionItemContent[]
    convertYoutubeLinkToID: (link: string) => string
    customizeJsData: Customize | undefined
    installationDetails: InstallationDetailsState
    submitButtonText: string | undefined
    onChange: (field: InstallationDetailsFields, value: string | number) => void
    generalState: GeneralState
}

export const renderInstallationDetails = (props: RenderInstallationDetailsProps): JSX.Element => {
    const {
        convertHouseConnectionOptionToOptionItemContent,
        convertJunctionBoxOptionToOptionItemContent,
        convertOpticFiberOptionToOptionItemContent,
        convertYoutubeLinkToID,
        customizeJsData,
        generalState,
        installationDetails,
        onChange,
    } = props
    const { t } = useTranslation()
    const renderTextfield = (
        value: string,
        field: InstallationDetailsFields,
        onChange: (field: InstallationDetailsFields, value: string) => void,
        required: boolean,
        maxLength: number,
        noTooltip?: boolean,
    ): JSX.Element => {
        const requiredIcon = required ? ' *' : ''
        return (
            <Grid item xs={12} sm={4}>
                <Box height={1} display={'flex'}>
                    <Grid item xs={11} style={{ marginRight: 8 }}>
                        <TextField
                            {...TestIDs.GET_INSTALLATION_DETAILS('TEXTFIELD', field)}
                            variant={'outlined'}
                            fullWidth={true}
                            label={t('installationDetailsStrings.' + field + 'Label') + requiredIcon}
                            value={value}
                            onChange={(e): void => {
                                onChange(field, e.target.value)
                            }}
                            type={'text'}
                            inputProps={{ maxLength: maxLength }}
                        />
                    </Grid>
                    {!noTooltip && (
                        <Grid item>
                            <Box width={1} height={1} className={'InfoTooltipContainer'} display={'flex'}>
                                <InfoTooltip title={t('installationDetailsStrings.' + field + 'Info') as string} />
                            </Box>
                        </Grid>
                    )}
                </Box>
            </Grid>
        )
    }
    const renderJunctionBox = (
        selected: string,
        field: InstallationDetailsFields,
        junctionBox: ConfigurationJunctionBox,
        onChange: (field: InstallationDetailsFields, value: string) => void,
    ): JSX.Element => {
        const options: OptionItemContent[] = convertJunctionBoxOptionToOptionItemContent(junctionBox)
        return (
            <Grid container spacing={1} style={{ marginTop: '45px' }}>
                <Grid item xs={12}>
                    <Typography {...TestIDs.GET_INSTALLATION_DETAILS('RADIO', field + '_Label')} variant={'h4'}>
                        {t('installationDetailsStrings.junctionBox.' + junctionBox.title)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <RadioOptionGroup
                        options={options}
                        selected={selected}
                        onSelect={(id: string): void => onChange(field, id)}
                    />
                </Grid>
            </Grid>
        )
    }
    const renderHouseConnection = (
        selected: string,
        field: InstallationDetailsFields,
        houseConnection: ConfigurationHouseConnection,
        onChange: (field: InstallationDetailsFields, value: string) => void,
    ): JSX.Element => {
        const options: OptionItemContent[] = convertHouseConnectionOptionToOptionItemContent(houseConnection)
        return (
            <Grid container spacing={1} style={{ marginTop: '45px' }}>
                <Grid item xs={12}>
                    <Typography {...TestIDs.GET_INSTALLATION_DETAILS('RADIO', field + '_Label')} variant={'h4'}>
                        {t('installationDetailsStrings.houseConnection.' + houseConnection.title)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <RadioOptionGroup
                        options={options}
                        selected={selected}
                        onSelect={(id: string): void => onChange(field, id)}
                    />
                    {selected === 'OptionTitle1' && (
                        <Grid container spacing={3} className={'installationDetailsAppartmentData'}>
                            {renderTextfield(
                                installationDetails.floorInformation ?? '',
                                InstallationDetailsFields.FLOOR_INFO,
                                onChange,
                                false,
                                50,
                            )}
                            {renderTextfield(
                                installationDetails.flatPosition ?? '',
                                InstallationDetailsFields.FLAT_POSITION,
                                onChange,
                                false,
                                50,
                            )}
                            {renderTextfield(
                                installationDetails.flatNumber ?? '',
                                InstallationDetailsFields.FLAT_NUMBER,
                                onChange,
                                false,
                                50,
                            )}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        )
    }
    const renderOpticFiber = (
        selected: string,
        field: InstallationDetailsFields,
        opticFiber: ConfigurationOpticFiber,
        onChange: (field: InstallationDetailsFields, value: string) => void,
    ): JSX.Element => {
        const options: OptionItemContent[] = convertOpticFiberOptionToOptionItemContent(opticFiber)
        return (
            <Grid container spacing={1} style={{ marginTop: '45px' }}>
                <Grid item xs={12}>
                    <Typography {...TestIDs.GET_INSTALLATION_DETAILS('RADIO', field + '_Label')} variant={'h4'}>
                        {t('installationDetailsStrings.opticFiber.' + opticFiber.title)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <RadioOptionGroup
                        options={options}
                        selected={selected}
                        onSelect={(id: string): void => onChange(field, id)}
                    />
                    {selected === 'OptionTitle1' && (
                        <Typography {...TestIDs.GET_CONFIGURATION('RADIO', 'TITLE')} variant={'h5'}>
                            {t('installationDetailsStrings.opticFiberInputTitle')}
                        </Typography>
                    )}
                    {selected === 'OptionTitle1' &&
                        renderTextfield(
                            installationDetails.opticFiberInput ?? '',
                            InstallationDetailsFields.OPTIC_FIBER_INPUT,
                            onChange,
                            false,
                            150,
                            true,
                        )}
                    {selected === 'OptionTitle1' && (
                        <Typography
                            {...TestIDs.GET_CONFIGURATION('RADIO', 'HELP_TEXT')}
                            variant={'subtitle1'}
                            className={'ConfigurationHelpText'}
                        >
                            {t('installationDetailsStrings.opticFiberInputHelperText')}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        )
    }

    const renderInstallationService = (
        selected: number,
        field: InstallationDetailsFields,
        service: ConfigurationInstallationService,
        onChange: (field: InstallationDetailsFields, value: string | number) => void,
    ): JSX.Element => {
        const optionItemContent: OptionItemContent = {
            id: field,
            price: {
                newPrice: service.option.cost,
                billMode: BillMode.IGNORE,
            },
            subtitle: t('installationDetailsStrings.installationService.' + service.option.subtitle) ?? '',
            info: t('installationDetailsStrings.installationService.' + service.option.information) ?? '',
            title: t('installationDetailsStrings.installationService.' + service.option.title),
        }
        const newLinkId = convertYoutubeLinkToID(service.video)
        return (
            <Grid container style={{ marginTop: '45px' }}>
                <Grid item xs={12}>
                    <Typography
                        {...TestIDs.GET_INSTALLATION_DETAILS('CHECK', field + '_TITLE')}
                        variant={'h2'}
                        className={'SectionCategory'}
                        dangerouslySetInnerHTML={{
                            __html: t('installationDetailsStrings.installationService.' + service.title),
                        }}
                    />
                </Grid>
                <Box width={1} marginTop={2}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={6}>
                            <Typography
                                {...TestIDs.GET_INSTALLATION_DETAILS('RADIO', field + '_SUBTITLE')}
                                variant={'h4'}
                                dangerouslySetInnerHTML={{
                                    __html: t('installationDetailsStrings.installationService.' + service.subtitle),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ReactPlayer
                                {...TestIDs.GET_INSTALLATION_DETAILS('VIDEO', field + '_REACT_PLAYER')}
                                width={'100%'}
                                controls
                                url={
                                    newLinkId !== ''
                                        ? `https://www.youtube-nocookie.com/embed/${newLinkId}`
                                        : service.video
                                }
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <OptionItem
                        type={OptionType.CHECKBOX}
                        content={optionItemContent}
                        selected={selected === service.option.cost}
                        onSelect={(): void => onChange(field, service.option.cost)}
                    />
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <Grid container spacing={3} className={'installationDetailsAppartmentData'}>
                {customizeJsData?.installationDetailsConfiguration?.appartmentData &&
                    renderTextfield(
                        installationDetails.floorInformation,
                        InstallationDetailsFields.FLOOR_INFO,
                        onChange,
                        customizeJsData?.installationDetailsConfiguration?.appartmentData.floorInformationRequired,
                        50,
                    )}
                {customizeJsData?.installationDetailsConfiguration?.appartmentData &&
                    renderTextfield(
                        installationDetails.flatPosition,
                        InstallationDetailsFields.FLAT_POSITION,
                        onChange,
                        customizeJsData?.installationDetailsConfiguration?.appartmentData.flatPositionRequired,
                        50,
                    )}
                {customizeJsData?.installationDetailsConfiguration?.appartmentData &&
                    renderTextfield(
                        installationDetails.flatNumber ?? '',
                        InstallationDetailsFields.FLAT_NUMBER,
                        onChange,
                        customizeJsData?.installationDetailsConfiguration?.appartmentData.flatNumberRequired,
                        50,
                    )}
            </Grid>
            {customizeJsData?.installationDetailsConfiguration?.junctionBox &&
                renderJunctionBox(
                    installationDetails.junctionBox,
                    InstallationDetailsFields.JUNCTION_BOX,
                    customizeJsData.installationDetailsConfiguration.junctionBox,
                    onChange,
                )}
            {customizeJsData?.installationDetailsConfiguration?.houseConnection &&
                renderHouseConnection(
                    installationDetails.houseConnection,
                    InstallationDetailsFields.HOUSE_CONNECTION,
                    customizeJsData.installationDetailsConfiguration.houseConnection,
                    onChange,
                )}
            {customizeJsData?.installationDetailsConfiguration?.opticFiber &&
                renderOpticFiber(
                    installationDetails.opticFiber,
                    InstallationDetailsFields.OPTIC_FIBER,
                    customizeJsData.installationDetailsConfiguration.opticFiber,
                    onChange,
                )}
            {customizeJsData?.installationDetailsConfiguration?.installationService &&
                renderInstallationService(
                    installationDetails.installationService,
                    InstallationDetailsFields.SERVICE,
                    customizeJsData.installationDetailsConfiguration.installationService,
                    onChange,
                )}
            <Box width={1} marginTop={2}>
                <Typography>{t('contactDataStrings.helperText')}</Typography>
            </Box>

            {generalState.inLineErrors.length > 0 && (
                <Grid container item xs={12}>
                    <ErrorBox errors={[{ category: '', errors: generalState.inLineErrors }]} />
                </Grid>
            )}
        </>
    )
}

const InstallationDetails = (): JSX.Element => {
    const {
        convertJunctionBoxOptionToOptionItemContent,
        convertHouseConnectionOptionToOptionItemContent,
        convertOpticFiberOptionToOptionItemContent,
        convertYoutubeLinkToID,
        currentView,
        customizeJsData,
        disabledSubmit,
        installationDetails,
        onChange,
        submitButtonText,
        generalState,
    } = useInstallationDetailsReducer()

    return (
        <ViewWrapper
            disabledSubmit={disabledSubmit}
            header={'installationDetailsHeader'}
            subHeader={'installationDetailsSubheader'}
            viewType={currentView}
            submitButtonText={submitButtonText}
        >
            {renderInstallationDetails({
                convertHouseConnectionOptionToOptionItemContent,
                convertJunctionBoxOptionToOptionItemContent,
                convertOpticFiberOptionToOptionItemContent,
                convertYoutubeLinkToID,
                customizeJsData,
                generalState,
                installationDetails,
                onChange,
                submitButtonText,
            })}
        </ViewWrapper>
    )
}

export default InstallationDetails
